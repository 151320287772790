import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BannerContainer = styled(Card)({
  padding: '12px',
  display: 'flex',
  alignItems: 'top',
  boxShadow: 'none',
  borderRadius: '8px',
});

interface IconProps {
  fillColor: string;
}

export const StyledIcon = styled('div', {
  shouldForwardProp: (prop) => prop !== 'fillColor',
})<IconProps>(({ fillColor }) => ({
  display: 'flex',
  paddingRight: '4px',
  path: {
    fill: fillColor,
  },
}));
